import { Hero } from "components/Hero";
import { MultiTicketShop } from "../components/MultiTicketShop";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Helmet from "react-helmet";

export default function EventsPage({ location }) {
  const title = "Events | Plush Oxford";
  const description = "Plush Oxford events.";

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />
      <Hero background={<StaticImage src="../images/heroTickets.png" alt="" />}>
        <h1>Events</h1>
      </Hero>
      <section className="events__ticket_shop">
        <MultiTicketShop theme="light" />
      </section>
    </>
  );
}
